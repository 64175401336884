import { Button } from "components/button/button";
import { FlexContent } from "components/FlexContent/FlexContent";
import { WarningIcon } from "components/icons/WarningIcon";
import { Spacer } from "components/spacer";
import { WarningMessage } from "components/views/Booking/styles";
import { EL_DUENDE_DOMAIN, TABLAO_DOMAIN } from "env";
import { BookingContext } from "providers/BookingProvider";
import { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { BannerHeadline, BannerHighlight, BannerText } from "./styles";

export const SoldoutBanner = ({ activeDate, isDuende }) => {
  const { adultTickets, childTickets } = useContext(BookingContext);
  const { formatMessage } = useIntl();
  return (
    <WarningMessage>
      <FlexContent
        justify="center"
        direction="column"
        alignItems="center"
        fullWidth
      >
        <FlexContent justify="center" fullWidth>
          <WarningIcon />
          <BannerHeadline>
            {formatMessage({
              defaultMessage: "Sold out at your preferred time?",
              id: "XW16e4",
            })}
          </BannerHeadline>
        </FlexContent>
        <Spacer mt={{ xs: 12, md: 15 }} />
        <BannerText>
          {isDuende
            ? formatMessage({
                defaultMessage:
                  "As an alternative, we recommend our second venue:",
                id: "tWC0/O",
              })
            : formatMessage({
                defaultMessage:
                  "As an alternative, we recommend our new venue:",
                id: "8+z1O1",
              })}
        </BannerText>

        <BannerHighlight>
          {isDuende
            ? formatMessage({
                defaultMessage: "Tablao Flamenco Cordobes",
                id: "T3G+rD",
              })
            : formatMessage({
                defaultMessage: "El Duende by Tablao Cordobes",
                id: "aS/erm",
              })}
        </BannerHighlight>
        <BannerText>
          {isDuende
            ? formatMessage({
                defaultMessage:
                  "Located at La Rambla 35, just a few steps away.",
                id: "Kakhtp",
              })
            : formatMessage({
                defaultMessage:
                  "Located at La Rambla 33, just a few steps away.",
                id: "p5xrrp",
              })}
        </BannerText>
        <Spacer mt={{ xs: 10 }} />
        <BannerText>
          {formatMessage({
            defaultMessage: "We hope you enjoy your experience at our tablao.",
            id: "W7hsFj",
          })}
        </BannerText>
        <Spacer mt={{ xs: 12, md: 15 }} />
        {isDuende ? (
          <a href={`https://${TABLAO_DOMAIN}`} target="_blank">
            <Button size="small">
              <FormattedMessage
                defaultMessage="Take me to Tablao Flamenco Cordobes"
                id="zAPGix"
              />
            </Button>
          </a>
        ) : (
          <a
            href={`https://${EL_DUENDE_DOMAIN}?date=${activeDate}&adults=${adultTickets}&child=${childTickets}`}
            target="_blank"
          >
            <Button size="small">
              <FormattedMessage
                defaultMessage="Take me to El Duende"
                id="00m+Rf"
              />
            </Button>
          </a>
        )}
      </FlexContent>
    </WarningMessage>
  );
};
