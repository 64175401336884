import styled from "styled-components";

export const StyledField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 0 0;
  margin-bottom: 16px;

  label {
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.gray100};
    padding-left: 8px;
  }

  input:focus + label {
    color: ${({ theme }) => theme.colors.yellow};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: calc(50% - 20px);
  }
`;

export const StyledForm = styled.form`
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .react-select__control {
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray100};
    border-radius: 0;
  }

  .react-select__control--is-focused {
    box-shadow: none;
    border-color: ${({ theme }) => theme.colors.yellow};
    border-bottom: 1px solid ${({ theme }) => theme.colors.yellow};
    border-radius: 0;

    &:hover {
      border-color: ${({ theme }) => theme.colors.yellow};
    }
  }

  .react-select__option--is-focused {
    background: ${({ theme }) => theme.colors.gray20};
  }

  .react-select__option {
    &:hover {
      background: ${({ theme }) => theme.colors.gray20};
    }
    &--is-selected {
      background: ${({ theme }) => theme.colors.yellow};
    }
  }
`;

export const StyledInput = styled.input`
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray100};
  padding: 6px 8px;
  font-size: 16px;

  &:focus {
    border-color: ${({ theme }) => theme.colors.yellow};
  }
`;

export const FormError = styled.p`
  color: ${({ theme }) => theme.colors.red};
  font-size: 10px;
  padding: 5px 0;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 12px;
  }
`;

export const StyledLink = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 24px 0 0;
  margin: 0 0 8px 20px;

  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.navy};
    font-size: 12px;
    line-height: 16px;
    padding-left: 8px;

    &:hover {
      color: ${({ theme }) => theme.colors.yellow};
    }
  }
`;
