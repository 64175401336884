export const STEP_START = "/";
export const STEP_CALENDAR = "calendar";
export const STEP_SEATS = "seats";
export const STEP_FORM = "form";
export const STEP_SUMMARY = "summary";
export const PAYMENT_LINK = "payment-link";
export const BOOKING_STEPS = [
  STEP_START,
  STEP_CALENDAR,
  STEP_SEATS,
  STEP_FORM,
  STEP_SUMMARY,
];

export const EVENT_TYPES_PRIORITY = [
  "show_and_dinner",
  "show_and_tapas",
  "show_and_drink",
  "show_and_drink_b",
  "show",
];

export const INVOICE_GOOGLE_FORM_LINKS = {
  es: "https://forms.gle/UN1GEfkbmN9t66rg7",
  ca: "https://forms.gle/UN1GEfkbmN9t66rg7",
  en: "https://forms.gle/a5HDWHaYibn4N4JX9",
  fr: "https://forms.gle/a5HDWHaYibn4N4JX9",
  it: "https://forms.gle/a5HDWHaYibn4N4JX9",
  ja: "https://forms.gle/a5HDWHaYibn4N4JX9",
};
