export const clearTextInput = (input: string) => {
  // Regular expression to match emojis
  const emojiRegex =
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|\uD83E[\uDD00-\uDFFF])/g;

  // Remove emojis and trim trailing white spaces
  const cleanedText = input.replace(emojiRegex, "").trim();

  return cleanedText;
};
