import styled from "styled-components";

export const StyledCalendarCarousel = styled.div`
  width: 100%;

  .slick-prev:before,
  .slick-next:before {
    color: ${({ theme }) => theme.colors.yellow};
  }

  .slick-next {
    right: -10px;
  }

  .slick-prev {
    left: -15px;
    z-index: 9;
  }
`;
