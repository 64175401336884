import {
  BigHeadline,
  BigParagraph,
  MediumParagraph,
  SectionHeadline,
} from "../typography.styles";
import { FormattedMessage, useIntl } from "react-intl";
import { Spacer } from "../spacer";
import { FlexContent } from "../FlexContent/FlexContent";
import { HOMEPAGE_URL_DUENDE, HOMEPAGE_URL_TABLAO } from "../../env";
import { Button } from "../button/button";
import { useContext, useEffect } from "react";
import TagManager from "react-gtm-module";
import { useSearchParams } from "react-router-dom";
import { BookingContext } from "providers/BookingProvider";

export const PaymentSuccess = () => {
  const { locale } = useIntl();
  const [searchParams] = useSearchParams();
  const { isDuende } = useContext(BookingContext);

  const sendGTMData = () => {
    let gtmInfo = localStorage.getItem("gtmInfo");
    const email = localStorage.getItem("email");
    if (email) {
      TagManager.dataLayer({
        dataLayer: {
          email,
        },
      });
    }
    try {
      gtmInfo = JSON.parse(gtmInfo);
      if (!gtmInfo) return;
      TagManager.dataLayer({
        dataLayer: {
          event: "purchase",
          ecommerce: gtmInfo,
        },
      });
    } catch (e) {
      console.error(e);
    }

    localStorage.removeItem("email");
    localStorage.removeItem("gtmInfo");
  };

  useEffect(() => {
    sendGTMData();
  }, []);

  return (
    <FlexContent
      direction="column"
      alignItems="center"
      justify="center"
      fullWidth
    >
      <BigHeadline>
        <FormattedMessage defaultMessage="Success! " id="jQjJ0W" />
      </BigHeadline>
      <Spacer mt={{ xs: 16, md: 24 }} />
      <BigParagraph textAlign="center">
        {isDuende ? (
          <FormattedMessage
            defaultMessage="Your payment was successful. If you do not receive an email confirmation
        in the next 15 minutes, your booking is not yet guaranteed, so please
        contact us urgently at +34 933 175 711 (office hours) or
        reservas@elduendebarcelona.com for any assistance.! "
            id="cDVYUi"
          />
        ) : (
          <FormattedMessage
            defaultMessage="Your payment was successful. If you do not receive an email confirmation
        in the next 15 minutes, your booking is not yet guaranteed, so please
        contact us urgently at +34 933 175 711 (office hours) or
        tablao@tablaocordobes.com for any assistance.! "
            id="eVECHW"
          />
        )}
      </BigParagraph>
      <Spacer mt={{ xs: 16, md: 24 }} />
      {searchParams.get("Ds_Currency_DCC") && (
        <FlexContent direction="column">
          <SectionHeadline>
            <FormattedMessage
              defaultMessage="Your payment summary"
              id="GCTRQ8"
            />
          </SectionHeadline>
          <div>
            <BigParagraph>
              <FormattedMessage defaultMessage="Amount" id="/0TOL5" />
              {`: ${searchParams.get("Ds_Amount")} EURO`}
            </BigParagraph>
            <BigParagraph>
              <FormattedMessage defaultMessage="DCC Amount" id="Ebq7/p" />
              {`: ${searchParams.get("Ds_Amount_DCC")} ${searchParams.get(
                "Ds_Currency_DCC",
              )}`}
            </BigParagraph>
            <BigParagraph>
              <FormattedMessage
                defaultMessage="DCC Exchange Rate"
                id="pbXDA7"
              />
              {`: ${searchParams.get("Ds_ExchangeRate_DCC")}`}
            </BigParagraph>
            {searchParams.get("Ds_Card_Brand") === "1" ? (
              <>
                <Spacer mt={{ xs: 8, md: 16 }} />
                <MediumParagraph>
                  <FormattedMessage
                    defaultMessage="I accept that I have been offered a choice of currencies for payment and that this choice is final."
                    id="f+hknP"
                  />
                </MediumParagraph>
                <MediumParagraph>
                  <FormattedMessage
                    defaultMessage="I accept the conversion rate, the final amount and the selected currency is"
                    id="6lEsxq"
                  />{" "}
                  {searchParams.get("Ds_Currency_DCC")}
                </MediumParagraph>
              </>
            ) : (
              <>
                <Spacer mt={{ xs: 8, md: 16 }} />
                {searchParams.get("Ds_Markup_DCC") && (
                  <>
                    <BigParagraph>
                      <FormattedMessage defaultMessage="Includes" id="qyDfOX" />
                      {` ${searchParams.get("Ds_Markup_DCC")}% `}
                      <FormattedMessage
                        defaultMessage="markup over wholesale rate provided by B. SABADELL"
                        id="gY/wNo"
                      />
                    </BigParagraph>
                    <Spacer mt={{ xs: 8, md: 16 }} />
                  </>
                )}

                <MediumParagraph>
                  <FormattedMessage
                    defaultMessage="I have chosen not to use the MasterCard currency conversion
                  process and agree that I will gave no recourse against
                  MasterCard concerning the currency conversion or its
                  disclosure."
                    id="URoQjp"
                  />
                </MediumParagraph>
              </>
            )}
          </div>
        </FlexContent>
      )}
      <Spacer mt={{ xs: 16, md: 24 }} />
      <a
        href={`${
          isDuende
            ? HOMEPAGE_URL_DUENDE
            : `${HOMEPAGE_URL_TABLAO}/${locale === "en" ? "" : locale}`
        }`}
      >
        <Button>
          <FormattedMessage defaultMessage="Go back to homepage" id="sij0JZ" />
        </Button>
      </a>
    </FlexContent>
  );
};
