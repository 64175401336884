import { Spacer } from "../../spacer";
import { SectionHeadline } from "../../typography.styles";
import { BookingContext } from "../../../providers/BookingProvider";
import { BOOKING_STEPS, STEP_START } from "../../../consts";

import { useContext, useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { FormattedMessage, useIntl } from "react-intl";
import { CalendarIcon } from "../../icons/Calendar";

import { bookingApi, LOCATIONS, SingleShowType } from "../../../api/booking";
import { parseCalendar, ParsedCalendarType } from "../../../utils/parseData";
import {
  StyledCalendar,
  StyledCalendarOverlay,
  StyledCalendarWrapper,
} from "./Calendar.styles";
import { Loader } from "../../loader";

import dayjs from "dayjs";
import { CalendarCarousel } from "../../calendarCarousel";
import { PeopleSelection } from "components/bookingComponents/PeopleSelection";
import { DrinkSelection } from "components/bookingComponents/DrinkSelection";
import { EventSelectionDuende } from "./EventSelectionDuende";
import { EventSelectionTablao } from "./EventSelectionTablao";
import { BookingHeader, StyledCalendarIcon } from "./styles";
import { SeoText } from "./SeoText";

export const Merged = () => {
  const {
    adultTickets,
    childTickets,
    startDate,
    setStartDate,
    activeProduct,
    setActiveProduct,
    setIsLoading,
    priceOption,
    isDuende,
    setActiveStep,
    enableNextStep,
    nextStepEnabled,
    drinks,
    setPriceOption,
  } = useContext(BookingContext);

  const { locale } = useIntl();
  const [activeDate, setActiveDate] = useState<string>(startDate);
  const [calendar, setCalendar] = useState<ParsedCalendarType>({});
  const [getAvailability, { data: shows, isLoading }] =
    bookingApi.endpoints.getAvailability.useLazyQuery();
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const [events, setEvents] = useState<SingleShowType[]>([]);
  const [doReload, setReload] = useState<number>(0);

  const flagEnabled = false;

  useEffect(() => {
    setActiveStep(BOOKING_STEPS.indexOf(STEP_START));
    enableNextStep(false);
  }, []);

  useEffect(() => {
    setReload(dayjs().unix());
  }, [locale]);

  useEffect(() => {
    if (!!flagEnabled) {
      let drinksSum = 0;
      drinks.forEach((drink) => {
        drinksSum += Object.values(drink)[0];
      });

      if (drinksSum === adultTickets + childTickets) {
        enableNextStep(true);
      } else if (nextStepEnabled) {
        enableNextStep(false);
      }
    }
  }, [drinks, nextStepEnabled, childTickets, adultTickets]);

  useEffect(() => {
    if (!flagEnabled) {
      if (priceOption && adultTickets > 0) {
        enableNextStep(true);
      } else {
        enableNextStep(false);
      }
    }
  }, [priceOption, adultTickets]);

  const getCalendar = (dateFrom) => {
    setReload(dayjs().unix());
    const dateTo = dayjs(dateFrom).add(90, "d").format("YYYY-MM-DD");
    getAvailability({
      people: (adultTickets + childTickets).toString(),
      dateFrom,
      dateTo,
      locations: isDuende ? ["duende"] : LOCATIONS,
    });
  };

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (activeDate) {
      const calendarKeys = Object.keys(calendar).sort((a, b) =>
        a < b ? -1 : 1,
      );

      // index of activeDate in calendar
      const activeDateIndex = calendarKeys.findIndex(
        (date) => date === activeDate,
      );

      if (activeDateIndex >= calendarKeys.length - 5) {
        getCalendar(activeDate);
      }
      if (calendar[activeDate]) {
        if (isDuende) {
          const activeTime = Object.keys(calendar[activeDate]).sort((a, b) =>
            a < b ? -1 : 1,
          )[0];
          setEvents(calendar[activeDate][activeTime]);
          setActiveProduct(calendar[activeDate][activeTime][0]);
        } else {
          setActiveProduct(null);
          setPriceOption(null);
        }
      }
    }
  }, [activeDate]);

  useEffect(() => {
    if (startDate && activeDate) {
      const startInResults = Object.keys(calendar).find(
        (date) => date === startDate,
      );
      if (startInResults) {
        setActiveDate(startDate);
      }
    }
  }, [startDate]);

  useEffect(() => {
    if (shows) {
      const { availabilities } = shows;
      if (availabilities.length > 0) {
        const parsedCalendar = parseCalendar({
          data: availabilities,
          guests: adultTickets + childTickets,
          isDuende,
        });

        if (Object.keys(parsedCalendar).length === 0) {
          return;
        }
        const sortedKeys = Object.keys(parsedCalendar).sort((a, b) =>
          a < b ? -1 : 1,
        );
        const initDate = sortedKeys[0];

        if (isDuende) {
          const activeTime = Object.keys(parsedCalendar[initDate]).sort(
            (a, b) => (a < b ? -1 : 1),
          )[0];

          setEvents(parsedCalendar[initDate][activeTime]);
          setActiveProduct(parsedCalendar[initDate][activeTime][0]);
          setActiveDate(initDate);
        } else {
          const eventType = Object.keys(parsedCalendar[initDate]).sort(
            (a, b) => (a < b ? -1 : 1),
          )[0];

          setEvents(parsedCalendar[initDate][eventType]);
          setActiveProduct(null);
          setActiveDate(initDate);
          setPriceOption(null);
        }

        setCalendar({ ...calendar, ...parsedCalendar });
      }

      // calendar has events but nothing new was fetched
      if (availabilities.length === 0 && Object.keys(calendar).length > 0) {
        const dates = Object.keys(calendar).sort((a, b) => (a < b ? -1 : 1));
        const initDate = dates[dates.length - 1];
        setActiveDate(initDate);
      }
    }
  }, [shows, startDate]);

  useEffect(() => {
    let start = startDate;
    if (!start) {
      start = dayjs().format("YYYY-MM-DD");
      setStartDate(start);
    }
    if (adultTickets + childTickets > 0) {
      getCalendar(start);
    }
  }, [adultTickets, childTickets, startDate]);

  if (Object.keys(calendar).length === 0 || events.length === 0) {
    return <Loader />;
  }

  return (
    <>
      <PeopleSelection />
      <>
        <Spacer mt={{ xs: 15, md: 40 }} />
        <BookingHeader justify="space-between">
          <SectionHeadline>
            2.{" "}
            <FormattedMessage
              defaultMessage="Select date and time for the show"
              id="4489Ui"
            />
          </SectionHeadline>
          <StyledCalendarIcon
            onClick={() => setIsCalendarOpen(!isCalendarOpen)}
          >
            <CalendarIcon />
          </StyledCalendarIcon>

          {isCalendarOpen && (
            <StyledCalendarWrapper>
              <StyledCalendarOverlay onClick={() => setIsCalendarOpen(false)} />
              <StyledCalendar
                value={startDate}
                onChange={(val: Date) => {
                  return [
                    setStartDate(dayjs(val).format("YYYY-MM-DD")),
                    setIsCalendarOpen(false),
                  ];
                }}
              />
            </StyledCalendarWrapper>
          )}
        </BookingHeader>
        <p>
          <FormattedMessage
            defaultMessage="Choose a date and time for your flamenco experience. Limited seats available per show!"
            id="dbqE3Z"
          />
        </p>
        <Spacer mt={{ xs: 16, md: 32 }} />
        <CalendarCarousel
          key={doReload}
          events={calendar}
          setActiveDate={setActiveDate}
          activeDate={activeDate}
          startDate={startDate}
        />
        {isDuende ? (
          <EventSelectionDuende
            calendar={calendar}
            events={events}
            setEvents={setEvents}
            activeDate={activeDate}
          />
        ) : (
          <EventSelectionTablao
            calendar={calendar}
            activeProduct={activeProduct}
            activeDate={activeDate}
          />
        )}
      </>

      {flagEnabled &&
        priceOption &&
        activeProduct.eventType === "show_and_drink" && <DrinkSelection />}

      <Spacer mt={{ xs: 15, md: 20 }} />
      {isDuende && <SeoText />}
    </>
  );
};
